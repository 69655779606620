import { Editor, BubbleMenu, isTextSelection } from "@tiptap/react"
import "tippy.js/animations/shift-away.css"
import { Transition } from "@headlessui/react"
import { useSelector } from "react-redux"

import { selectHighlights } from "features/pageSlice"
import { useEffect, useRef, useState } from "react"
import NewHighlightCollect from "./Popover/New/NewHighlightCollect"
import useOutsideClick from "hooks/useOutsideClick"

import { useAnalytics } from "hooks/useAnalytics"
import useBlogHasCollectibles from "hooks/collectibles/useBlogHasCollectibles"

type Props = {
  editor: Editor
}

export default function ReaderToolbar({ editor }: Props) {
  const highlights = useSelector(selectHighlights)
  const [shouldHide, setShouldHide] = useState(false)
  const [isMouseUp, setIsMouseUp] = useState(false)
  const [clickedPopover, setClickedPopover] = useState(false)
  const blogHasCollectibles = useBlogHasCollectibles()

  const { track } = useAnalytics()

  const ref = useRef<HTMLDivElement>(null)
  useOutsideClick(ref, () => setClickedPopover(false))

  // Special handling for the case where a user is attempting to highlight
  // something that was already highlighted.
  useEffect(() => {
    // Never show the popover if the user is on mobile.
    if (isUserTouching) {
      setShouldHide(true)
      return
    }

    // If the user is actively clicking the popover, never hide it.
    if (clickedPopover) {
      setShouldHide(false)
      return
    }

    // If teh mouse is clicked down (eg the user is selecting text),
    // wait until they finish before showing the popover.
    if (isMouseUp === false) {
      setShouldHide(true)
      return
    }

    const doAnyHighlightsOverlap = highlights.some((value) => {
      const highlight = value.collectible

      // We aggressively check that a user's highlights are completely
      // distinct from each other.
      const doesDocHaveMark = editor.state.doc.rangeHasMark(
        editor.state.selection.from,
        editor.state.selection.to,
        editor.state.schema.marks.highlight
      )

      const isOverlapping =
        editor.state.selection.from <= highlight.position.from &&
        editor.state.selection.to >= highlight.position.to

      // Need this so that a user's highlights are completely distinct.
      // Without this, a user may highlight something that someone else
      // previously highlighted.
      const isStartingOrEnding =
        editor.state.selection.from === highlight.position.from ||
        editor.state.selection.to === highlight.position.to ||
        editor.state.selection.from === highlight.position.to ||
        editor.state.selection.to === highlight.position.from

      const shouldWeHide =
        isOverlapping || isStartingOrEnding || doesDocHaveMark

      if (shouldWeHide) {
        // I dont know why, but setting this to 5 fixes the issue with
        // selection.
        editor?.commands.setTextSelection(5)
      }

      console.log("Should we hide?", shouldWeHide)

      return shouldWeHide
    })

    setShouldHide(doAnyHighlightsOverlap)
  }, [
    editor.state.selection.from,
    editor.state.selection.to,
    highlights,
    isMouseUp,
    clickedPopover,
  ])

  useEffect(() => {
    console.log("ShouldHide changed state: ", shouldHide)
  }, [shouldHide])

  const [isUserTouching, setIsUserTouching] = useState(false)

  useEffect(() => {
    function mouseUp() {
      setIsMouseUp(true)
    }

    function mouseNotUp() {
      setIsMouseUp(false)
    }

    window.addEventListener("mouseup", mouseUp)
    window.addEventListener("mousedown", mouseNotUp)

    return () => {
      window.removeEventListener("mouseup", mouseUp)
      window.removeEventListener("mouseup", mouseNotUp)
    }
  }, [])

  // Disable highlight collection for mobile.
  useEffect(() => {
    function touchingFn() {
      if (!isUserTouching) {
        setIsUserTouching(true)
      }
    }

    window.addEventListener("touchstart", touchingFn)

    return () => window.removeEventListener("touchstart", touchingFn)
  }, [])

  // The blog needs to have a wallet address associated to them
  // in order for highlights to be collected.
  if (!blogHasCollectibles) return null

  return (
    <div>
      {/** This needs to be wrapped in a div because BubbleMenu requires a parent element. */}
      <BubbleMenu
        editor={editor}
        pluginKey="highlightToolbar"
        shouldShow={({ editor, view, state, oldState, from, to }) => {
          let containsNonText = false

          // Only allow certain nodes to be highlighted and selected.
          const ALLOWED_NODE_TYPES = [
            "paragraph",
            "text",
            "listItem",
            "bulletList",
            "blockquote",
          ]

          state.doc.nodesBetween(from, to, (node) => {
            if (!ALLOWED_NODE_TYPES.includes(node?.type?.name)) {
              console.log("Node is not allowed.", node)
              containsNonText = true
            }
          })

          if (containsNonText || !isTextSelection(state.selection)) {
            console.log(
              "Selection contains non-text, so not showing popover.",
              state.selection
            )
            return false
          }

          // Sometime check for `empty` is not enough
          const isEmptyTextBlock =
            !state.doc.textBetween(from, to).length &&
            isTextSelection(state.selection)

          if (state.selection.empty || isEmptyTextBlock) {
            console.log("Selection is empty.", state.selection)
            return false
          }

          // If codeblock -> Hide
          if (editor.isActive("codeBlock")) {
            console.log("Selection is a code block.", state.selection)
            return false
          }

          const text = state.doc.textBetween(from, to)

          console.log("Displaying popover.")
          track("new highlight popover displayed", { text })

          return true
        }}
        tippyOptions={{
          maxWidth: "600px",
          plugins: [],
          duration: [200, 150],
          animation: "shift-away",
          inertia: true,

          popperOptions: {
            strategy: "absolute",
            placement: "top",
            modifiers: [
              {
                name: "offset",
                options: {
                  // This is needed so that when someone highlights text,
                  // the toolbar doesn't overlap with the text.
                  offset: [50, 50],
                },
              },
            ],
          },
        }}
        className="transition-all"
      >
        <Transition
          appear={true}
          show={!shouldHide}
          unmount={false}
          enter="transition duration-100 ease-out"
          enterFrom="transform scale-95 opacity-0"
          enterTo="transform scale-100 opacity-100"
          leave="transition duration-75 ease-out"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform scale-95 opacity-0"
        >
          <div
            className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5"
            ref={ref}
            onMouseDown={(_e) => setClickedPopover(true)}
          >
            <div className="relative grid gap-6 bg-white sm:gap-8 rounded-lg px-5 py-6">
              <NewHighlightCollect
                editor={editor}
                close={() => setShouldHide(true)}
              />
            </div>
          </div>
        </Transition>
      </BubbleMenu>
    </div>
  )
}
