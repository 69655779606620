import { BubbleMenu, isTextSelection } from "@tiptap/react"
import { Editor } from "@tiptap/core"
import { BiCodeAlt } from "react-icons/bi"
import "tippy.js/animations/shift-away.css"
import {
  MdFormatAlignLeft,
  MdFormatAlignCenter,
  MdFormatAlignRight,
  MdFormatAlignJustify,
  MdFormatBold,
  MdFormatItalic,
  MdFormatStrikethrough,
  MdFormatUnderlined,
  MdFormatIndentIncrease,
  MdFormatIndentDecrease,
  MdInsertChartOutlined,
} from "react-icons/md"
import ToolbarButton from "../ToolbarButton"
import { hideOnEsc } from "../../../util/tippy"
import { RiLinkM } from "react-icons/ri"
import { MutableRefObject } from "react"

type Props = {
  editor: Editor
  textToolbar: MutableRefObject<string>
  linkToolbar: MutableRefObject<string>
} & typeof defaultProps

const defaultProps = Object.freeze({})

export default function TextFloatingToolbar({
  editor,
  textToolbar,
  linkToolbar,
}: Props): JSX.Element {
  function shouldShow() {
    return ({ editor, view, state, oldState, from, to }: any) => {
      // Sometime check for `empty` is not enough
      const isEmptyTextBlock =
        !state.doc.textBetween(from, to).length &&
        isTextSelection(state.selection)

      if (!view.hasFocus() || state.selection.empty || isEmptyTextBlock)
        return false

      // If codeblock -> Hide
      if (editor.isActive("codeBlock")) return false

      // Should only show for text selections
      if (state.selection?.node?.type?.name) return false

      // Don't show for customButton
      if (state.selection?.parent?.type?.name === "customButton") return false

      return true
    }
  }

  // Note: Non-Alignable text elements (Lists & Block Quotes have depth > 1)
  //       Make an exception for tables
  const isAlignable =
    editor.state.selection.$anchor.depth === 1 ||
    // @ts-ignore
    editor.state.selection?.$anchor.node(1)?.type.name === "table"

  const isIndentable =
    editor.can().sinkListItem("listItem") ||
    editor.can().liftListItem("listItem")

  const isLink = editor.isActive("link")

  return (
    <BubbleMenu
      editor={editor}
      pluginKey="textToolbar"
      // @ts-ignore
      shouldShow={shouldShow()}
      tippyOptions={{
        maxWidth: "600px",
        plugins: [hideOnEsc],
        duration: [100, 150],
        animation: "shift-away",
        inertia: true,
      }}
      className="bg-gray-100 text-lg rounded-sm shadow-lg"
    >
      <ToolbarButton
        icon={<MdFormatBold />}
        altText="Bold"
        disabled={editor.isActive("code")}
        onClick={() => editor.chain().focus().toggleBold().run()}
        isActive={editor.isActive("bold")}
        isGroupStart={false}
      />
      <ToolbarButton
        icon={<MdFormatItalic />}
        altText="Italic"
        disabled={editor.isActive("code")}
        onClick={() => editor.chain().focus().toggleItalic().run()}
        isActive={editor.isActive("italic")}
        isGroupStart={false}
      />
      <ToolbarButton
        icon={<MdFormatUnderlined />}
        altText="Underline"
        disabled={editor.isActive("code")}
        onClick={() => editor.chain().focus().toggleUnderline().run()}
        isActive={editor.isActive("underline")}
        isGroupStart={false}
      />
      <ToolbarButton
        icon={<MdFormatStrikethrough />}
        altText="Strikethrough"
        disabled={editor.isActive("code")}
        onClick={() => editor.chain().focus().toggleStrike().run()}
        isActive={editor.isActive("strike")}
        isGroupStart={false}
      />
      <ToolbarButton
        icon={<BiCodeAlt />}
        altText="Code"
        onClick={() => editor.chain().focus().toggleCode().run()}
        isActive={editor.isActive("code")}
        isGroupStart={false}
      />
      <ToolbarButton
        icon={<RiLinkM />}
        altText="Link"
        disabled={editor.isActive("code")}
        onClick={() => {
          textToolbar.current = "hide"
          linkToolbar.current = "show"
          editor.chain().focus().run()
          // HACK: I don't love this, but it works for now...
          setTimeout(() => {
            textToolbar.current = "show"
            linkToolbar.current = "hide"
          }, 200)
        }}
        isActive={editor.getAttributes("link").href}
        isGroupStart={false}
      />
      {isLink && (
        <ToolbarButton
          icon={<MdInsertChartOutlined />}
          altText="Convert link to link preview"
          onClick={() =>
            editor
              .chain()
              .focus()
              .extendMarkRange("link")
              .setEmbedly({ src: editor.getAttributes("link").href })
              .run()
          }
        />
      )}
      {isIndentable && (
        <>
          <ToolbarButton
            icon={<MdFormatIndentDecrease />}
            altText="Decrease Indent"
            onClick={() => editor.commands.liftListItem("listItem")}
            disabled={!editor.can().liftListItem("listItem")}
            isGroupStart
          />
          <ToolbarButton
            icon={<MdFormatIndentIncrease />}
            altText="Increase Indent"
            onClick={() => editor.commands.sinkListItem("listItem")}
            disabled={!editor.can().sinkListItem("listItem")}
            isGroupStart={false}
          />
        </>
      )}
      {isAlignable && (
        <>
          <ToolbarButton
            icon={<MdFormatAlignLeft />}
            altText="Align Left"
            onClick={() => editor.commands.setTextAlign("left")}
            isActive={editor.isActive({ textAlign: "left" })}
            isGroupStart
          />
          <ToolbarButton
            icon={<MdFormatAlignCenter />}
            altText="Align Center"
            onClick={() => editor.commands.setTextAlign("center")}
            isActive={editor.isActive({ textAlign: "center" })}
            isGroupStart={false}
          />
          <ToolbarButton
            icon={<MdFormatAlignRight />}
            altText="Align Right"
            onClick={() => editor.commands.setTextAlign("right")}
            isActive={editor.isActive({ textAlign: "right" })}
            isGroupStart={false}
          />
          <ToolbarButton
            icon={<MdFormatAlignJustify />}
            altText="Align Justify"
            onClick={() => editor.commands.setTextAlign("justify")}
            isActive={editor.isActive({ textAlign: "justify" })}
            isGroupStart={false}
          />
        </>
      )}
    </BubbleMenu>
  )
}

TextFloatingToolbar.defaultProps = defaultProps
