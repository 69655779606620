import { useSelector } from "react-redux"

import { selectBlog } from "features/blogSlice"
import { selectCurrentNote } from "features/noteSlice"
import { blogOrNoteHasCollectibles } from "@/util/collectibles"

/**
 * This hook ensures that the blog has collectibles enabled,
 * and a wallet address connected.
 */
export default function useBlogHasCollectibles() {
  const blog = useSelector(selectBlog)
  const note = useSelector(selectCurrentNote)

  return blogOrNoteHasCollectibles(blog, blog.user, note)
}
