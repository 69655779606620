import { User } from "@/types/users"
import { Blog, Note } from "@types"

/**
 * This function checks if the blog or the note (if specified) has collectibles.
 */
export function blogOrNoteHasCollectibles(
  blog: Blog,
  blogOwner?: User,
  note?: Note | null
) {
  // Necessary conditions to be collectible:
  // Blog
  // - Blog owner with wallet address OR Blog with collectible wallet address specified
  // - Blog with disabled_highlights set to false
  // Note (the above and)
  // - Note with collectiblesDisabled set to false

  if (
    ((blogOwner && "wallet_address" in blogOwner) ||
      blog.collectibleWalletAddress) &&
    !!blog.disable_highlights === false &&
    (!note || (note && !!note.collectiblesDisabled === false))
  )
    return true

  return false
}
