import { Editor } from "@tiptap/react"

import useDebounce from "hooks/useDebounce"
import useOutsideClick from "hooks/useOutsideClick"
import { useRef } from "react"
import BasePopover from "../BasePopover"
import useNewHighlightMetadata from "hooks/collectibles/useNewHighlightMetadata"

import CreateNewCollectibleBtn from "./CreateNewCollectibleBtn"
import RainbowConnectWalletBtn from "@/components/ConnectWalletButton"
import { selectBlog } from "features/blogSlice"
import { selectCurrentNote } from "features/noteSlice"
import { useSelector } from "react-redux"

import { useAccount } from "wagmi"
import { HighlightPosition } from "@/types/highlights"
import useCollectPrecondition from "hooks/collectibles/useCollectPrecondition"
import useUser from "hooks/useUser"
import MintFeeText from "../MintFeeText"

type Props = {
  position: HighlightPosition
  text: string
}

function CollectNewHighlightBtn(props: Props) {
  const note = useSelector(selectCurrentNote)
  const blog = useSelector(selectBlog)
  const accountData = useAccount()
  const collectPrecondition = useCollectPrecondition()
  const { walletReadyAndLoggedIn } = useUser()

  const metricData = useNewHighlightMetadata()

  if (!walletReadyAndLoggedIn) {
    return (
      <div className="flex justify-center flex-col w-full">
        <RainbowConnectWalletBtn text="Connect wallet" />
      </div>
    )
  }

  if (!collectPrecondition) return null

  const highlightName = note?.title || blog.name || "Collectible Highlight"
  const highlightSymbol =
    blog?.lowercase_url?.substring(1).toUpperCase() || "PARA"
  const minterAddress = accountData.address

  return (
    <CreateNewCollectibleBtn
      {...props}
      name={highlightName}
      type="HIGHLIGHT"
      symbol={highlightSymbol}
      ownerAddress={metricData.ownerAddress}
      // @ts-ignore We confirmed presence in collectPrecondition
      minterAddress={minterAddress}
      costWei={metricData.priceWei}
      supply={metricData.maxSupply}
      chain={metricData.chain}
      position={props.position}
      text={props.text}
    />
  )
}

export default function NewHighlightCollect(props: {
  editor: Editor
  close: () => void
}) {
  const selection = props.editor.state.selection
  const { from, to } = selection
  const text = props.editor.state.doc.textBetween(from, to)
  const metricData = useNewHighlightMetadata()

  const ref = useRef(null)

  console.log("Rendering reader popover...")

  // Need to debounce this so we don't generate a LOT of image requests
  const debouncedText = useDebounce(text, 500)

  // We need to use a unique key for every selection,
  // to ensure that the state is reset correctly (eg error messages).
  const newHighlightKey = JSON.stringify(selection.toJSON())

  // If this is a straggling highlight (eg someone purchased it, then left the page
  // before the highlight was updated in our database to COMPLETE), this will
  // properly set the state.
  // useUpdateHighlightState({ highlight: props.highlight })

  // If we click outside the modal, close it.
  useOutsideClick(ref, () => {
    console.log("Clicked outside modal, so closing.")
    props.close()
  })

  if (props.editor.state.selection.empty) {
    console.log("Selection is empty, so not rendering anything.")
    return null
  }

  const Body = (
    <>
      <p className="text-gray-500 mt-3">
        Collect this highlight to permanently own it. You'll be the first owner.
      </p>
    </>
  )
  const Footer = (
    <>
      <CollectNewHighlightBtn position={{ from, to }} text={text} />
      <MintFeeText chain={metricData.chain} />
    </>
  )

  // User is collecting a new highlight. It doesn't exist yet.
  console.log("Rendering new highlight collect popover...")
  return (
    <div ref={ref} className="z-50">
      <BasePopover
        body={Body}
        footer={Footer}
        imageText={debouncedText}
        key={newHighlightKey}
        metricData={metricData}
        collectibleType="HIGHLIGHT"
      />
    </div>
  )
}
