import { EditorContent, Editor } from "@tiptap/react"
import Script from "next/script"
import TextToolbar from "./TextToolbar"
import ImageToolbar from "./ImageToolbar"
import LinkToolbar from "./LinkToolbar"
import { MutableRefObject } from "react"
import { MenuState } from "components/Editor/constants"
import CollectNewHighlightMenu from "components/Collectibles/CollectNewHighlightMenu"
import EmbedToolbar from "./EmbedToolbar"
import TableToolbar from "./TableToolbar"

type Props = {
  editor: Editor
  textToolbar?: MutableRefObject<MenuState>
  imageToolbar?: MutableRefObject<MenuState>
  linkToolbar?: MutableRefObject<MenuState>
  embedToolbar?: MutableRefObject<MenuState>
  tableToolbar?: MutableRefObject<MenuState>
}

const Tiptap = (props: Props) => {
  return (
    <>
      <Script src="/js/lite-youtube-embed.js" strategy="lazyOnload" />
      {/* Note: This wrapping div is necessary to prevent a 'Failed to execute removeChild on Node' error */}
      <div>
        {props.editor &&
          props.editor.isEditable &&
          props.textToolbar &&
          props.linkToolbar && (
            <TextToolbar
              editor={props.editor}
              textToolbar={props.textToolbar}
              linkToolbar={props.linkToolbar}
            />
          )}
        {props.editor && props.editor.isEditable && props.linkToolbar && (
          <LinkToolbar editor={props.editor} linkToolbar={props.linkToolbar} />
        )}
        {props.editor && !props.editor.isEditable && (
          <CollectNewHighlightMenu editor={props.editor} />
        )}

        {props.editor && props.editor.isEditable && props.embedToolbar && (
          <EmbedToolbar editor={props.editor} />
        )}
        {props.editor &&
          props.editor.isEditable &&
          props.imageToolbar &&
          props.linkToolbar && (
            <ImageToolbar
              editor={props.editor}
              imageToolbar={props.imageToolbar}
              linkToolbar={props.linkToolbar}
            />
          )}
        {props.editor && props.editor.isEditable && props.tableToolbar && (
          <TableToolbar editor={props.editor} toolbar={props.tableToolbar} />
        )}
      </div>
      <EditorContent
        dir="auto"
        className="h-full w-full"
        editor={props.editor}
      />
    </>
  )
}

export default Tiptap
