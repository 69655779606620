import { FocusPosition, useEditor } from "@tiptap/react"
import type { MenuState } from "components/Editor/constants"

import { postClassNames } from "@/util/constants/style"
import { useRef } from "react"
import { cleanupJsonBeforeLoad, cleanupJsonBeforeSave } from "util/format"
import EmptyState from "components/Editor/EmptyState"

import { extensions } from "components/Editor/extensions"

type Props = {
  contentEditable: boolean
  initialContent: string
  onUpdate?: (json: string) => void
  onCreate?: () => void
  disableAiButtons?: boolean
  autofocus?: FocusPosition
  overridePostClassNames?: string
}

export default function useCustomEditor(props: Props) {
  const linkToolbar = useRef<MenuState>("hide")
  const imageToolbar = useRef<MenuState>("hide")
  const embedToolbar = useRef<MenuState>("hide")
  const textToolbar = useRef<MenuState>("show")
  const tableToolbar = useRef<MenuState>("hide")

  let exts = extensions
  if (props.disableAiButtons) exts = extensions.filter((e) => e != EmptyState)

  const editor = useEditor({
    editable: props.contentEditable,
    extensions: exts,
    autofocus: props.autofocus || false,
    editorProps: {
      attributes: {
        class: props.overridePostClassNames || postClassNames,
        id: "paragraph-tiptap-editor",
        translate: "yes",
      },
    },
    onUpdate: ({ editor }) => {
      let json = editor.getJSON()
      const cleanupState = cleanupJsonBeforeSave(json)
      if (cleanupState.skipSave) {
        console.log("Pending embed. Skipping update...")
        return
      }
      json = cleanupState.json
      props.onUpdate && props.onUpdate(JSON.stringify(json))
      console.log("Updated json: ", json)
    },
    onCreate: () => props.onCreate && props.onCreate(),
    content: props.initialContent
      ? cleanupJsonBeforeLoad(JSON.parse(props.initialContent || "{}"))
      : "",
  })

  return {
    editor,
    textToolbar,
    imageToolbar,
    embedToolbar,
    linkToolbar,
    tableToolbar,
  }
}
