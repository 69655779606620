import { Editor, isTextSelection } from "@tiptap/react"
import { BubbleMenu } from "@tiptap/react"
import { useEffect, useState } from "react"
import { RiLinkUnlinkM } from "react-icons/ri"
import { VscLinkExternal } from "react-icons/vsc"
import ToolbarButton from "../ToolbarButton"
import { hideOnEsc } from "../../../util/tippy"

type Props = {
  editor: Editor
  linkToolbar: any
} & typeof defaultProps

const defaultProps = Object.freeze({})

export default function LinkToolbar({
  editor,
  linkToolbar,
}: Props): JSX.Element {
  const nodeType =
    // @ts-ignore
    editor.state.selection?.node?.type.name === "image" ? "imageLink" : "link"
  const [href, setHref] = useState<string | undefined>(undefined)
  const [linkType, setLinkType] = useState(nodeType)

  // Autofocus on mount
  const linkInput = window.document.getElementById("linkInput")
  useEffect(() => {
    if (linkInput) linkInput.focus()
  }, [linkInput])

  useEffect(() => {
    const nodeType =
      // @ts-ignore
      editor.state.selection?.node?.type?.name === "image"
        ? "imageLink"
        : "link"
    setHref(undefined)
    setLinkType(nodeType)
  }, [editor.state.selection])

  function shouldShow() {
    return ({ view, state, from, to }: any) => {
      // Force hide
      if (linkToolbar.current === "hide") return false

      // Sometime check for `empty` is not enough
      const isEmptyTextBlock =
        !state.doc.textBetween(from, to).length &&
        isTextSelection(state.selection)

      if (!view.hasFocus() || state.selection.empty || isEmptyTextBlock)
        return false

      return true
    }
  }

  const openInNewTab = (url: string): void => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer,nofollow")
    if (newWindow) newWindow.opener = null
  }

  const onKeyPressHandler = (e: React.KeyboardEvent<HTMLElement>) => {
    if (e.key === "Enter") {
      e.preventDefault()

      if (href === "") {
        linkType === "link"
          ? editor.chain().focus().extendMarkRange("link").unsetLink().run()
          : editor.chain().focus().unsetImageLink().run()
        return
      }

      if (href === undefined) return

      // Make sure the url has the protocol or add 'https' as default
      const src = href.includes(":") ? href : `https://${href}`

      if (linkType === "link")
        editor
          .chain()
          .focus()
          .extendMarkRange("link")
          .setLink({ href: src })
          .setTextSelection(editor.state.selection.$head.pos)
          .run()
      else {
        editor
          .chain()
          .focus()
          .unsetImageLink()
          .setImageLink({ href: src })
          .run()
      }

      setHref(undefined)
    }
  }

  return (
    <BubbleMenu
      editor={editor}
      pluginKey="linkInput"
      // @ts-ignore
      updateDelay={0}
      tippyOptions={{
        maxWidth: "320px",
        placement: "bottom",
        interactive: true,
        plugins: [hideOnEsc],

        duration: [100, 150],
        animation: "shift-away",
        inertia: true,
      }}
      // @ts-ignore
      shouldShow={shouldShow()}
      className="bg-gray-100 text-lg rounded-sm shadow-lg"
    >
      <div className="flex items-center" onBlur={() => setHref("")}>
        <input
          className="appearance-none border rounded ml-2 mr-1 w-52  px-3 py-[2px] text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          id="linkInput"
          type="text"
          autoComplete="off"
          onKeyPress={onKeyPressHandler}
          value={
            href !== undefined
              ? href
              : editor.getAttributes(linkType).href || ""
          }
          // @ts-ignore
          onInput={(e) => setHref(e.target.value)}
          placeholder={"Enter a link..."}
        ></input>
        <ToolbarButton
          icon={<VscLinkExternal />}
          onClick={() => openInNewTab(editor.getAttributes(linkType).href)}
          altText="Open"
          // @ts-ignore
          disabled={!editor.getAttributes(linkType).href}
          isActive={false}
        />
        <ToolbarButton
          icon={<RiLinkUnlinkM />}
          onClick={() =>
            linkType === "link"
              ? editor.chain().focus().extendMarkRange("link").unsetLink().run()
              : editor.chain().focus().unsetImageLink().run()
          }
          altText="Remove"
          // @ts-ignore
          disabled={!editor.getAttributes(linkType).href}
          isActive={false}
        />
      </div>
    </BubbleMenu>
  )
}

LinkToolbar.defaultProps = defaultProps
